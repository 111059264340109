<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span>
          {{ $_strings.terminationContract.label.TYPE_OF_TERMINATION_CONTRACT}}<span class="red--text">*</span>
        </span>
      </v-col>
      <v-col class="pt-0" cols="12" sm="auto">
        <v-radio-group
          v-model="form.terminateType"
          :rules="rules.terminateTypeRules"
          class="ma-0"
          :disabled="readonly"
          @change="handleChangeTerminateType"
        >
          <v-radio
            :key="1"
            :value="'FULL_TERMINATION'"
            :label="`${$_strings.terminationContract.label.EXPIRATION_OF_THE_PERIOD}`"></v-radio>
          <v-radio
            :key="2"
            :value="'EARLY_TERMINATION'"
            :label="`${$_strings.terminationContract.label.TERMINATION_CONTRACT_BEFORE_EXPIRATION_PERIOD}`"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span>{{$_strings.addendumContract.label.PARTNER_TYPE}}<span class="red--text">*</span></span>
      </v-col>
      <v-col class="pt-0" cols="12" sm="auto">
        <v-radio-group
          class="ma-0"
          v-model="form.companyType"
          @change="() => {
            fetchCompanyList('');
            handleChangeCompanyType();
          }"
          :rules="rules.partnerRule"
          :disabled="readonly"
        >
          <v-radio :key="1" value="SHIPPER" label="Shipper"></v-radio>
          <v-radio :key="2" value="TRANSPORTER" label="Rekanan 3PL"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{$_strings.contract.header.COMPANY_NAME}} <span class="red--text">*</span></span>
      </v-col>
      <v-col md="9">
        <v-tooltip v-model="showTooltipCompanyName" left>
          <template v-slot:activator="{ }">
            <v-autocomplete
              dense
              outlined
              item-text="companyName"
              item-value="companyId"
              placeholder="Nama Perusahaan"
              :rules="rules.companyNameRules"
              :items="itemsCompanyList"
              v-model="form.companyId"
              @change="($event) => setForm($event)"
              :loading="isLoadingCompanyList"
              @input.native="($event) => fetchCompanyList($event.target.value)"
              :clearable="!readonly"
              :disabled="readonly"
              @focus="!form.companyType ? showTooltipCompanyName = true : showTooltipCompanyName = false"
              @blur="showTooltipCompanyName = false"
            >
            </v-autocomplete>
          </template>
          <p>{{$_strings.addendumContract.label.PARTNER_TYPE}} Belum Dipilih</p>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.label.MOU_NUMBER }}<span class="red--text">*</span></span>
      </v-col>
      <v-col md="9">
        <v-text-field
          :value="form.mouNo"
          :rules="rules.mouRule"
          dense
          outlined
          disabled
          placeholder="Nomor MoU"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.header.CONTRACT_NUMBER}} <span class="red--text">*</span></span>
      </v-col>
      <v-col md="9">
        <v-text-field
          height="30"
          outlined
          dense
          placeholder="Nomor Kontrak"
          v-model="form.contractNo"
          disabled
          aria-hidden="true"
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.label.EFFECTIVE_PERIOD}} <span class="red--text">*</span></span>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          height="30"
          outlined
          dense
          placeholder="Tanggal"
          :value="form.periodStart ? dayjs(form.periodStart).format('DD/MM/YYYY') : null"
          disabled
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto">
        <span class="subtitle-1">{{ $_strings.contract.label.UNTIL}} <span class="red--text">*</span></span>
      </v-col>
      <v-col md="3">
        <v-text-field
          height="30"
          outlined
          dense
          placeholder="Tanggal"
          :value="form.periodEnd ? dayjs(form.periodEnd).format('DD/MM/YYYY') : null"
          disabled
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5" sm="4" md="3">
        <p>{{ $_strings.terminationContract.label.TERMINATION_LETTER_NUMBER}}<span class="red--text">*</span></p>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="d-flex justify-start align-center"
      >
        <v-text-field
          height="30"
          outlined
          dense
          v-model="form.endingDocNo"
          :placeholder="`${$_strings.terminationContract.label.TERMINATION_LETTER_NUMBER}`"
          :disabled="readonly"
          :rules="rules.endingDocNoRules"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.terminationContract.label.EFFECTIVE_TERMINATION_CONTRACT }}<span class="red--text">*</span></span>
      </v-col>
      <v-col
        cols="auto"
      >
        <v-menu
          ref="effectiveDateShow"
          v-model="effectiveDateShow"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              prepend-inner-icon="mdi-calendar-month"
              label="Tanggal"
              single-line
              outlined
              dense
              :value="form.effectiveDatetime ? dayjs(form.effectiveDatetime).format('DD/MM/YYYY') : null"
              :rules="rules.dateRule"
              aria-hidden="true"
              readonly
              persistent-hint
              v-bind="attrs"
              :disabled="form.terminateType === 'FULL_TERMINATION' || readonly"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.effectiveDatetime"
            @change="handleChangEeffectiveDatetime"
            no-title
            :disabled="readonly"
            @input="effectiveDateShow = false"
            :min="form.periodStart ? dayjs(form.periodStart).format('YYYY-MM-DD') : null"
            :max="form.periodEnd ? dayjs(form.periodEnd).format('YYYY-MM-DD') : null"
            locale="id"
          ></v-date-picker>
        </v-menu>
        <p class="font-italic subtitle-2" v-if="form.effectiveDatetime">
          <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          {{ $_strings.terminationContract.label.TIME_TERMINATION_CONTRACT }}
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex">
      <v-col cols="5" sm="4" md="3" class="d-flex align-center">
        <p>
          {{ $_strings.terminationContract.label.TERMINATION_REASON_CONTRACT
          }}<span class="red--text">*</span>
        </p>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-select
          dense
          outlined
          :loading="isLoadingReasonList"
          :items="itemsReasonList"
          item-text="reason"
          v-model="form.endingReasonId"
          item-value="id"
          :disabled="readonly || form.terminateType === 'FULL_TERMINATION'"
          :placeholder="`${$_strings.terminationContract.label.TERMINATION_REASON_CONTRACT}`"
          :rules="rules.endingReasonRules"
          @change="onEndingReasonChange"
        >
        </v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    newUploadDocuments: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (!this.readonly) {
      this.fetchReasonList();
    }
  },
  data() {
    return {
      showTooltipCompanyName: false,
      rules: {
        terminateTypeRules: [(v) => !!v || 'Jenis Pengakhiran Kontrak wajib dipilih'],
        companyNameRules: [(v) => !!v || 'Nama perusahaan wajib diisi'],
        partnerRule: [(v) => !!v || 'Mitra Kerja wajib dipilih'],
        endingDocNoRules: [(v) => !!v || 'Nomor surat pengakhiran wajib diisi'],
        dateRule: [(v) => !!v || 'Tanggal wajib diisi'],
        endingReasonRules: [(v) => !!v || 'Alasan pemutusan kontrak wajib diisi'],
      },
      endingReasonList: [],
      itemsCompanyList: [],
      radioGroupCompany: null,
      isLoadingCompanyList: false,
      isLoadingReasonList: false,

      // DATE
      signedDateShow: false,
      effectiveDateShow: false,
    };
  },
  computed: {
    action() {
      const [, , , path] = this.$route.path.split('/');
      return path;
    },
    itemsReasonList() {
      if (dayjs(this.form.effectiveDatetime).format('YYYY-MM-DD') !== dayjs(this.form.periodEnd).format('YYYY-MM-DD')) {
        const findReason = this.endingReasonList.find((reason) => reason.reason.toLowerCase().includes('masa berlaku'));
        if (findReason) {
          return this.endingReasonList.filter((ending) => !(ending.id === findReason.id));
        }
        return this.endingReasonList;
      }
      return this.endingReasonList;
    },
  },
  methods: {
    dayjs,
    handleChangEeffectiveDatetime() {
      if (this.form.terminateType !== 'FULL_TERMINATION') {
        this.form.endingReasonId = null;
        this.form.endingReasonText = '';
      }
    },
    handleChangeTerminateType() {
      if (this.form.terminateType === 'FULL_TERMINATION') {
        if (this.form.periodEnd) {
          this.form.effectiveDatetime = dayjs(this.form.periodEnd).format('YYYY-MM-DD');
        }
        const findReason = this.endingReasonList.find((reason) => reason.reason.toLowerCase().includes('masa berlaku'));
        this.form.endingReasonId = findReason.id;
        this.onEndingReasonChange(findReason.id);
      } else {
        this.form.endingReasonId = null;
      }
    },
    handleChangeCompanyType() {
      this.itemsCompanyList = [];
      this.form.companyId = '';
      this.form.effectiveDatetime = null;
      this.setForm();
    },
    async fetchReasonList() {
      try {
        this.isLoadingReasonList = true;
        const result = await this.$_services.terminationContract.fetchReasonList();
        this.endingReasonList = result.data.contents;
      } finally {
        this.isLoadingReasonList = false;
      }
    },
    onEndingReasonChange(val) {
      this.form.endingReasonText = this.endingReasonList.find((el) => el.id === val).reason;
    },
    async fetchCompanyList(companyName = '') {
      try {
        this.isLoadingCompanyList = true;
        const { companyType } = this.form;
        const result = await this.$_services.contract.fetchCompanyListExistsContract(companyName, companyType);
        this.itemsCompanyList = [...this.itemsCompanyList, ...result.data.contents];
      } finally {
        this.isLoadingCompanyList = false;
      }
    },
    async fetchContractCompanyById(companyId) {
      const { contractId } = this.$route.params;

      try {
        this.$root.$loading.show();
        const result = await this.$_services.terminationContract.fetchContractCompanyById({ id: companyId });
        const { data } = result;
        if (data) {
          this.form.mouNo = data.mouNo;
          this.form.contractNo = data.contractNo;
          this.form.periodStart = data.periodStart;
          this.form.periodEnd = data.periodEnd;
          this.form.mouId = data.mouId;
          this.form.documents = [...this.newUploadDocuments, ...data.documents];
          if (this.form.terminateType === 'FULL_TERMINATION') {
            this.form.effectiveDatetime = this.form.periodEnd;
          }
          if (contractId !== data.id) {
            this.$router.replace({
              params: {
                ...this.$route.params,
                contractId: data.id,
              },
            });
          }
        }
      } finally {
        this.$root.$loading.hide();
      }
    },
    setForm(companyId) {
      const { contractId } = this.$route.params;

      if (!companyId) {
        this.form.mouNo = '';
        this.form.contractNo = '';
        this.form.periodStart = '';
        this.form.periodEnd = '';
        this.form.mouId = '';
        this.form.documents = this.newUploadDocuments;
        if (contractId) {
          this.$router.replace({
            params: {
              ...this.$route.params,
              contractId: null,
            },
          });
        }
        return;
      }
      this.fetchContractCompanyById(companyId);
    },
    generateContractNumber(val) {
      const mouArray = val.split('/');
      mouArray[1] = 'LGL';
      const result = `DRAFT-${mouArray.join('/')}`;
      return result;
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <p class="ml-2"><strong>{{ $_strings.terminationContract.label.PAGE_NAME }}</strong></p>
        <v-tabs
          mobile-breakpoint=""
          v-model="tab"
          background-color="transparent"
          active-class="font-weight-bold"
        >
          <v-tab class="text-capitalize body-1 mr-4">
            <span>{{$_strings.terminationContract.label.CONTRACT}}</span>
          </v-tab>
          <v-tab class="text-capitalize body-1 mr-4">
            <span>{{ $_strings.contract.label.ATTACHMENT }}</span>
          </v-tab>
          <v-tab class="text-capitalize body-1 mr-4">
            <span>{{$_strings.terminationContract.label.ADDITIONAL_EVIDENCE}}</span>
          </v-tab>
          <v-tab v-if="approval" class="text-capitalize body-1">
            <span>{{ $_strings.approval.APPROVAL_PAGE_NAME }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="transparent"
        >
          <v-tab-item>
            <v-card
              outlined
              class="transparent"
            >
              <section class="white pa-5 elevation-1">
                <FormInput
                  :readonly="readonly"
                  :form="form"
                  :newUploadDocuments="newUploadDocuments"
                  ref="form"
                />
              </section>
              <section class="pa-3">
                <TableUpload
                  :readonly="readonly"
                  :form="form"
                  :documents="form.documents"
                  :newUploadDocuments="newUploadDocuments"
                  documentType="CONTRACT"
                  documentStatus="DRAFT"
                />
              </section>
              <section class="d-flex justify-end">
                <v-btn
                  class="mt-4"
                  color="primary"
                  large
                  @click="tab = 1"
                >
                  {{ $_strings.contract.label.NEXT }}
                </v-btn>
              </section>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              outlined
              class="transparent"
            >
              <v-container fluid class="white pa-5 elevation-1">
                <TableUpload
                  :readonly="readonly"
                  class="pl-2 pr-2 pb-5 pt-0"
                  :form="form"
                  :documents="form.documents"
                  :newUploadDocuments="newUploadDocuments"
                  documentType="ATTACHMENT"
                  :showButton="false"
                />
                <!-- BUTTONS ACTIONS -->
                <v-row justify="end">
                  <v-btn
                    class="mt-4 mr-2"
                    color="primary"
                    large
                    @click="tab = 2"
                  >
                    {{ $_strings.contract.label.NEXT }}
                  </v-btn>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              outlined
              class="transparent"
            >
              <v-container fluid class="white pa-5 elevation-1">
                <TableUpload
                  :readonly="readonly"
                  class="pl-2 pr-2 pb-5 pt-0"
                  :form="form"
                  :documents="form.documents"
                  :newUploadDocuments="newUploadDocuments"
                  documentType="SUPPORT"
                  documentStatus="DRAFT"
                  label="Unggah Bukti Pendukung"
                />
                <!-- BUTTONS ACTIONS -->
                <v-row v-if="!readonly">
                  <v-col>
                    <v-btn
                      depressed
                      outlined
                      large
                      @click="tab = 0"
                    >
                      {{ $_strings.contract.label.BACK }}
                    </v-btn>
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      color="primary"
                      class="ml-4"
                      large
                      @click="submit"
                      v-if="!readonly"
                    >
                      {{$_strings.contract.label.SAVE}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              outlined
            >
              <Approval
                :workflowData="workflowData"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import dayjs from 'dayjs';
import FormInput from './FormInput.vue';
import TableUpload from '../TableUpload.vue';
import Approval from './Approval.vue';

export default {
  components: {
    FormInput,
    TableUpload,
    Approval,
  },
  mounted() {
    const { data } = this.$route.params;
    const { tab } = this.$route.query;
    if (data) {
      this.setData(data);
    }
    if (!data && this.approval) {
      this.fetchWorkFlowData();
    }
    if (this.action === 'view') {
      this.fetchContractDetail();
    }
    if (+tab === 2) {
      this.tab = 3;
    } else {
      this.tab = tab;
    }
  },
  beforeDestroy() {
    if (this.newUploadDocuments.length && !this.isSubmited) {
      const dataUrl = [];
      this.newUploadDocuments.forEach((doc) => {
        dataUrl.push({
          url: doc.url,
        });
      });
      Promise.all(dataUrl.map((payload) => this.$_services.contract.deleteFile(payload)));
    }
  },
  data() {
    return {
      isRevise: false,
      workflowData: {},
      tab: 0,
      form: {
        companyId: '',
        companyType: '',
        terminateType: '',
        mouId: null,
        endingDocNo: '',
        endingReasonId: '',
        endingReasonText: '',
        periodStart: null,
        periodEnd: null,
        effectiveDatetime: null,
        documents: [],
      },
      newUploadDocuments: [],
      histories: [],
      isSubmited: false,
    };
  },
  computed: {
    approval() {
      if (this.contractBy === 'approval-inbox' || this.contractBy === 'approval-process' || this.contractBy === 'complete-approval') {
        return true;
      }
      return false;
    },
    contractBy() {
      const [, , path] = this.$route.path.split('/');
      return path;
    },
    action() {
      // RETURN => CREATE | VIEW
      const [, , , path] = this.$route.path.split('/');
      return path;
    },
    readonly() {
      if (this.workflowData.workflowId) {
        let revise = false;
        const { isRevise } = this.workflowData;
        revise = (isRevise && this.contractBy === 'approval-inbox');
        return !revise;
      }
      return this.action === 'view';
    },
  },
  methods: {
    dayjs,
    setData(data) {
      this.workflowData = data;
      this.isRevise = data.isRevise;
      this.form = {
        ...data.data,
        effectiveDatetime: dayjs(data.data.effectiveDatetime).format('YYYY-MM-DD'),
      };
      // SET COMPANY LIST FOR SHOW COMPANY NAME
      this.$refs.form.itemsCompanyList.push({
        companyName: this.form.companyName,
        companyId: this.form.companyId,
      });
      // SET COMPANY ENDING REASON FOR SHOW REASON
      this.$refs.form.endingReasonList.push({
        id: this.form.endingReasonId,
        reason: this.form.endingReasonText,
      });
    },
    async fetchWorkFlowData() {
      const { id } = this.$route.params;
      this.$root.$loading.show();
      try {
        const result = await this.$_services.contract.getDetailWorkflowContract(id);
        const { data } = result;
        this.setData(data);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async fetchContractDetail() {
      const { contractId } = this.$route.params;
      try {
        this.$root.$loading.show();
        const res = await this.$_services.contract.fetchContractDetailById(contractId);
        this.form = res.data;

        // SET COMPANY LIST FOR SHOW COMPANY NAME
        this.$refs.form.itemsCompanyList.push({
          companyName: res.data.companyName,
          companyId: res.data.companyId,
        });
        // SET COMPANY ENDING REASON FOR SHOW REASON
        this.$refs.form.endingReasonList.push({
          id: res.data.endingReasonId,
          reason: res.data.endingReasonText,
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    validate() {
      if (!this.$refs.form.$refs.form.validate()) {
        this.tab = 0;
        return false;
      }
      // DOKUMEN
      if (this.form.documents.filter((doc) => doc.documentType === 'SUPPORT').length === 0) {
        this.$dialog.notify.error('Minimal dokumen kontrak harus di upload');
        return false;
      }
      return true;
    },
    async submitRevise() {
      if (this.validate()) {
        const filterDocuments = this.form.documents.filter((doc) => !doc.isDeleted);
        const { workflowId, workflowDetailId } = this.workflowData;
        const newForm = {
          ...this.form,
          documents: filterDocuments,
        };
        this.$root.$loading.show();
        try {
          await this.$_services.contract.reviseContract(newForm, workflowId, workflowDetailId);
          this.isSubmited = true;
          this.$dialog.notify.success('Revisi berhasil dikirim');
          this.$router.replace(`/main/${this.contractBy}`);
        } finally {
          this.$root.$loading.hide();
        }
      }
    },
    async submitTerminationContract() {
      const { contractId } = this.$route.params;
      if (this.validate()) {
        try {
          this.$root.$loading.show();
          await this.$_services.terminationContract.submitTermination({ id: contractId, payload: this.form });
          this.isSubmited = true;
          this.$dialog.notify.success('Berhasil');
          this.$router.replace(`/main/${this.contractBy}`);
        } finally {
          this.$root.$loading.hide();
        }
      }
    },
    submit() {
      if (this.approval) {
        this.submitRevise();
        return;
      }
      this.submitTerminationContract();
    },
  },
};
</script>

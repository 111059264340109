<template>
  <section class="mt-4">
    <v-row v-if="!readonly && showButton" class="pl-0 pr-0">
      <v-col class="pl-0 pr-0">
        <v-btn
          v-if="uploadProgress === 0"
          outlined
          color="primary"
          class="mr-2 mb-2"
          elevation="1"
          :disabled="!form.contractNo"
          @click="$refs.dialogUpload.dialog = true"
        >
          {{ textButton }}
        </v-btn>
        <div v-else>
          <v-progress-linear
            v-model="uploadProgress"
            height="25"
          >
            <strong>{{ Math.ceil(uploadProgress) }}%</strong>
          </v-progress-linear>
        </div>
      </v-col>
    </v-row>
    <v-row class="white elevation-1">
      <v-col class="pa-0">
        <v-data-table
          :items="items"
          :headers="headers"
          class="elevation-1"
          :items-per-page="100"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]={item}>
            <v-btn
              color="red darken-2"
              dark
              icon
              v-if="!readonly"
              :loading="item.isLoadingRemoveFile"
              @click="removeContract(item)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <!-- <v-btn
              color="red darken-2"
              dark
              icon
              v-if="canRemove(item)"
              :loading="item.isLoadingRemoveFile"
              @click="removeContract(item)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn> -->
            <v-btn
              color="primary"
              dark
              icon
              :loading="item.isLoadingDownload"
              @click="onButtonDownload(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogUpload
      ref="dialogUpload"
      :label="label"
      @uploadFile="uploadFile"
    />
  </section>
</template>
<script>
import dayjs from 'dayjs';
import DialogUpload from './Dialog/Upload.vue';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    documents: {
      type: Array,
      default: () => [],
    },
    newUploadDocuments: {
      type: Array,
      default: () => [],
    },
    documentType: {
      type: String,
      default: () => '',
    },
    documentStatus: {
      type: String,
      default: () => 'DRAFT',
    },
    showButton: {
      type: Boolean,
      default: () => true,
    },
    readonly: {
      type: Boolean,
      default: () => true,
    },
    label: {
      type: String,
      default: () => '',
    },
  },
  components: {
    DialogUpload,
  },
  computed: {
    items() {
      return this.documents.filter((doc) => doc.documentType === this.documentType);
    },
    contractBy() {
      // adendum-kontrak | pengakhiran-kontrak | kontrak-shipper | kontrak-3pl
      const [, , path] = this.$route.path.split('/');
      return path;
    },
    textButton() {
      if (this.documentType === 'ATTACHMENT') {
        return this.$_strings.contract.label.UPLOAD_ATTACHMENT;
      }
      if (this.contractBy === 'adendum-kontrak') {
        return this.$_strings.addendumContract.label.UPLOAD_ADDENDUM;
      }
      if (this.documentType === 'SUPPORT') {
        return this.$_strings.terminationContract.label.UPLOAD_ADDITIONAL_EVIDENCE;
      }
      return this.$_strings.contract.label.UPLOAD_CONTRACT;
    },
    action() {
      const [, , , path] = this.$route.path.split('/');
      return path;
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$_strings.contract.header.DOCUMENT_NAME,
          class: 'white--text primary',
          value: 'documentName',
          sortable: false,
        },
        {
          text: this.$_strings.contract.header.STATUS,
          class: 'white--text primary',
          value: 'documentStatus',
          sortable: false,
        },
        {
          text: this.$_strings.contract.header.ACTION,
          class: 'white--text primary',
          value: 'actions',
          sortable: false,
        },
      ],
      uploadProgress: 0,
    };
  },
  methods: {
    canRemove(item) {
      return this.newUploadDocuments.find((newDocument) => newDocument.url === item.url);
    },
    async removeContract(item) {
      const payload = { url: item.url };
      this.$set(item, 'isLoadingRemoveFile', true);
      try {
        const { id: workflowId } = this.$route.params;
        if (workflowId) {
          await this.$_services.contract.deleteDocumentContract(workflowId, item.url);
        } else {
          await this.$_services.contract.deleteFile(payload);
        }
        this.documents.splice(this.documents.indexOf(item), 1);
        this.newUploadDocuments.splice(this.newUploadDocuments.indexOf(item), 1);
        this.$dialog.notify.success('Berhasil menghapus dokumen');
      } finally {
        this.$set(item, 'isLoadingRemoveFile', false);
      }
    },
    onButtonDownload(item) {
      this.$set(item, 'isLoadingDownload', true);
      try {
        if (item.url) {
          this.$_services.uploads.downloadFile(item.url);
        }
      } finally {
        this.$set(item, 'isLoadingDownload', false);
      }
    },
    async uploadFile(formAttachment, encodedFile) {
      this.$refs.dialogUpload.dialog = false;
      try {
        const { companyId, contractNo } = this.form;
        const fileName = `${this.documentType.toLowerCase()}_${dayjs().unix()}.${formAttachment.fileType}`;
        const filePath = `contract/${dayjs().get('year')}/${companyId}/${contractNo.split('/')[0]}/`;
        const self = this;
        const config = {
          onUploadProgress(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            self.uploadProgress = percentCompleted;
          },
        };
        this.uploadProgress = 1;
        const result = await this.$_services.contract.uploadFile({ encodedFile, fileName, filePath }, config);
        const { url } = result.data;
        const data = {
          documentName: formAttachment.name,
          documentStatus: this.documentStatus,
          documentType: this.documentType,
          url,
        };
        this.documents.push(data);
        this.newUploadDocuments.push(data);
      } finally {
        this.uploadProgress = 0;
      }
    },
  },
};
</script>
